<div class="blue-card">


  <h3 class="subtitle-card">Booster Rounds</h3>

  <div class="step">
    <img class="icon-ray" src="../../../../assets/svg/icon-ray.svg" alt="Icono Rayo" />
    <p>
      <b>Y si vas mal de puntos...</b> <br />
      Nada está decidido: ¡a lo largo del campeonato habrá
      <b>Booster Rounds que podrán cambiarlo todo!</b>
    </p>
    <img class="icon-dot" src="../../../../assets/svg/icon-dot.svg" alt="Icono punto" />
    <p>
      Cada acierto <b>suma 250 Soles</b>, <br>
      pero cada fallo <b>resta -250 Soles</b>.
    </p>
    <img class="icon-dot" src="../../../../assets/svg/icon-dot.svg" alt="Icono punto" />
    <p>Estas rondas se juegan <br>
      <b>sin comodines ni bonus</b>. La puntuación 
      del equipo será la <b>media de vuestras 
      puntuaciones individuales</b>.</p>
  </div>

  
  <div class="step title-awards">
    <h3 class="subtitle-card">Los premios</h3>
    <img class="icon-award" src="../../../../assets/svg/icon-trophy.svg" alt="Icono porcentaje" />
    <div class="awards">
      <h4 class="subtitle-yellow">Premios para los 3 mejores equipos:</h4>
      <div>
        <p>1. Experiencia <b>MotoGP Valencia 2024</b></p>
        <p>2. Experiencia <b>Final Premier Pádel 2024</b></p>
        <p>3. Experiencia <b>Semis Premier Pádel 2024</b></p>
      </div>
    </div>
  </div>

  <div class="step">
    <div class="awards">
      <h4 class="subtitle-yellow">Premios para los 3 mejores jugadores:</h4>
      <div>
        <p>1. Inscripción y viaje al <b>Congreso EADV 2024</b></p>
        <p>2. Inscripción y viaje al <b>Congreso GEDET 2024</b></p>
        <p>3. Inscripción y viaje al <b>Congreso GEDOC 2024</b></p>
      </div>
    </div>
  </div>
</div>
