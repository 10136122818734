<app-layout [isHome]="hasBoosterRound">
  @if(loading){
  <div style="padding-top: 100px">
    <app-spinner />
  </div>
  } @else{
  <div class="container">
    <div class="cards-container">
      @if(userLoading){
      <app-spinner />
      }@else{
      <a routerLink="/profile">
        <score-card
          [imageUrl]="'../../assets/logos/' + scoreInfo.team.avatar + '.svg'"
          [score]="scoreInfo.team.points"
          [labelPrimary]="scoreInfo.team.name"
          [labelSecondary]="scoreInfo.team.center"
          name="name"
        />
      </a>
      <a routerLink="/profile">
        <score-card
          [imageUrl]="getProfileImageSrc()"
          [score]="scoreInfo.user.points"
          [labelPrimary]="scoreInfo.user.name"
          labelSecondary=""
          name="name"
        />
      </a>
      }
    </div>
    <div
      [class]="
        wasAnsweredRound ? 'logo-container answered' : 'logo-container normal'
      "
    >
      <img
        [src]="
          'assets/' +
          (wasAnsweredRound
            ? 'logo-comingSoon-home.svg'
            : 'logo-newRound-home.svg')
        "
        alt=""
      />

      <a class="link-content">
        <app-button
          [text]="wasAnsweredRound ? 'resultados ronda previa' : 'Jugar'"
          [classBtn]="'btn-secondary'"
          [disable]="
            waitingFirstRound || (currentRoundNumber == 1 && wasAnsweredRound)
          "
          (onClick)="handleCickPlay()"
        />
      </a>
    </div>
    @if(!hideCounter){
    <app-countdown [date]="endRoundDate" [label]="getcounterLabel()" />
    }
    <div class="button-content">
      <a>
        <app-button
          text="Rankings"
          [classBtn]="'btn-secondary button-ranking'"
          [disable]="waitingFirstRound || currentRoundNumber === 1"
          (onClick)="handleClickRanking()"
        />
      </a>
    </div>

    @if(hasBoosterRound){
    <button class="booster-card" (click)="toggleBoosterModal()">
      <img src="assets/images/logo-booster-round.png" alt="" />
    </button>
    }
  </div>

  }
</app-layout>

<p-dialog
  [(visible)]="visibleAnsweredModal"
  [modal]="true"
  [style]="{ width: '85%' }"
  [draggable]="false"
  [resizable]="false"
  [showHeader]="false"
  class="center-modal-container dialog-empty-round"
>
  <button class="float-btn" (click)="handleCloseDialog()">
    <img src="../../assets/icons/icon-close-round.svg" alt="" />
  </button>
  <div class="container">
    <h2>
      ¡DESCUBRE LOS RESULTADOS <br />
      DE LA RONDA {{ lastClosedRoundNumber
      }}{{
        hasBoosterResults
          ? " Y LA BOOSTER ROUND " + boosterRoundResultsInfo.number + "!"
          : "!"
      }}
    </h2>
    <p>
      Ya disponibles las respuestas correctas <br />
      y sus explicaciones. ¡No te los pierdas!
    </p>

    <app-button
      text="Ver Resultados"
      [classBtn]="'btn-secondary btn-dialog btn-results'"
      (onClick)="handleCickDialog()"
      [loading]="loading"
    />
  </div>
</p-dialog>

<p-dialog
  [(visible)]="visibleNotificationsModal"
  [modal]="true"
  [style]="{ width: '85%' }"
  [draggable]="false"
  [resizable]="false"
  [showHeader]="false"
  class="center-modal-container"
>
  <button class="float-btn" (click)="handleCloseNotificationDialog()">
    <img src="../../assets/icons/icon-close-round.svg" alt="" />
  </button>
  <div class="container notifications-modal">
    <p class="notifications-help">
      Para una mejor experiencia, agrega la aplicación a la pantalla de inicio
    </p>

    <div class="step-1">
      <p>En iPhone, o iPad, pulsa el boton "compartir" del menú inferior</p>
      <img src="../../assets/icons/icon-up.svg" alt="" />
    </div>

    <div class="step-2">
      <p class="choice-text">
        Elige "añadir a pantalla de inicio" <br />
        o "Instalar aplicación"
      </p>
      <div class="icons-links">
        <img src="../../assets/icons/icon-more.svg" alt="" />
        <img src="../../assets/icons/icon-phone.svg" alt="" />
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog
  [(visible)]="isBoosterModalVisible"
  [modal]="true"
  [style]="{ width: '85%' }"
  [draggable]="false"
  [resizable]="false"
  [showHeader]="false"
  class="center-modal-container dialog-booster"
>
  <button class="float-btn" (click)="toggleBoosterModal()">
    <img src="../../assets/icons/icon-close-round.svg" alt="" />
  </button>
  <div class="container">
    <img
      src="assets/images/logo-booster-round-positive.png"
      alt=""
      class="logo"
    />
    <p class="label-primary">
      ¡Esto puede cambiarlo todo! Esta es una ronda opcional. Si decides jugar,
      cada acierto suma 100 puntos a tu puntuación individual, ¡pero los fallos
      restan -100 puntos!
    </p>
    <h4 class="label-secondary">
      Además, también sumarás o restarás 250 puntos a tu equipo (haciendo media
      con el número de compañeros de tu equipo).
    </h4>
    <span class="label"> ¿Te atreves?</span>

    <app-button
      text="Jugar"
      [classBtn]="'btn-secondary btn-dialog btn-results'"
      (onClick)="handleBoosterClick()"
    />
    <app-button
      text="QUIZÁS MÁS TARDE"
      [classBtn]="'btn-primary btn-dialog btn-results'"
      (onClick)="toggleBoosterModal()"
    />
  </div>
</p-dialog>
