<section class="container">
  <div class="logo-container">
    <img
      class="banner"
      [src]="
        isBooster
          ? '../../../assets/images/logo-booster-round.png'
          : '../../../assets/images/round-banner.png'
      "
      alt=""
    />
    <p
      [class]="
        currentRoundNumber === 1
          ? 'round-number one'
          : currentRoundNumber <= 9
          ? 'round-number'
          : currentRoundNumber === 11
          ? 'round-number eleven'
          : 'round-number two-numbers'
      "
    >
      {{ isBooster ? "" : currentRoundNumber }}
    </p>
  </div>

  <div class="content">
    <p>¿Preparado?</p>
    <app-button
      text="¡A Jugar!"
      [classBtn]="'btn-primary'"
      (click)="handleClick()"
    />
  </div>
</section>
