<auth-layout>
  <div class="formBox">
    <h1 class="title-main">Cambio de contraseña</h1>

    @if(changedPassword){
    <div class="fedback-container">
      <p>
        Tu contraseña ha sido cambiada con éxito. Ahora puedes acceder con tu
        nueva contraseña.
      </p>
      <a routerLink="/login">
        <app-button text="Acceder" [classBtn]="'btn-primary'" />
      </a>
    </div>
    }@else{
    <div class="input-container">
      <app-input
        placeholder="Nueva contraseña"
        (change)="onUpdatePassword($event)"
        [errorMessage]="errorMessage"
        type="password"
        [eyeIcon]="true"
      />
      <app-input
        placeholder="Repetir nueva contraseña"
        (input)="onRepeatPassword($event)"
        type="password"
        [eyeIcon]="true"
      />
    </div>
    <div class="button-container">
      <app-button
        [text]="isLoading ? 'Cambiando' : 'Cambiar'"
        [classBtn]="'btn-primary'"
        (onClick)="onSubmit()"
        [loading]="isLoading"
        [disable]="!matchedPassword"
      />
      <a routerLink="/login">
        <app-button text="Cancelar" [classBtn]="'btn-secondary'" />
      </a>
    </div>
    }
  </div>
</auth-layout>
