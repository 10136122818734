<div class="blue-card">
  <h3 class="subtitle-card">Puntuaciones</h3>
  <div class="step">
    <img src="../../../../assets/svg/icon-sun.svg" alt="Icono sol" />

    <p>
      ¡Cada acierto suma <b>+50 Soles!</b> La puntuación del equipo será
      <b>la media de vuestras puntuaciones individuales.</b> <br />
      ¡Cada punto cuenta!
    </p>
    <br />
    <p>
      Pero además, puedes conseguir <br />
      <b>bonus</b> que se suman directamente a tu puntuación y la de tu equipo:
    </p>
  </div>

  <div class="step">
    <img
      src="../../../../assets/svg/icon-teamBonus.svg"
      alt="Icono team bonus"
    />

    <div>
      <h4 class="subtitle-yellow">Team Bonus</h4>
      <p>
        ¡Juega en equipo! Si todos acertáis una misma pregunta,
        <b> ¡cada miembro del equipo se llevará +5 Soles adicionales! </b>
      </p>
    </div>
  </div>

  <div class="step">
    <img src="../../../../assets/svg/icon-time.svg" alt="Icono tiempo" />

    <div>
      <h4 class="subtitle-yellow">Speed Bonus</h4>
      <p>
        ¡Los 10 primeros en acertar <br />
        se llevarán <b>+5 Soles!</b>
      </p>
    </div>
  </div>
  <!-- QUITAR
  <div class="step">
    <img
      src="../../../../assets/svg/calendar-icon.svg"
      alt="Icono Calendario"
    />

    <div>
      <h4 class="subtitle-yellow">Monday Bonus</h4>
      <p>
        ¡Empieza el lunes con energía! Si no te<br />
        has llevado el Speed Bonus,
        <b
          >puedes <br />
          llevarte +3 Soles si respondes antes <br />de que termine el lunes.
        </b>
      </p>
    </div>
  </div> -->
</div>
