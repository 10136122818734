<app-layout
  title="Mi perfil"
  (formEvent)="handleClickBack()"
  [isDarkBlue]="openProfileEditor"
>
  @if(openProfileEditor){
  <profile-editor [user]="user" />
  }@else{
  <section class="container">
    @if(loading){
    <div style="padding-top: 10px">
      <app-spinner />
    </div>
    }@else{
    <div class="player-data">
      <div class="main-avatar">
        <img
          [src]="
            getUserPictureSrc({
              userId: user.id,
              hasPicture: user.picture,
              fallbackUrl: '../../assets/avatars/' + user.avatar + '.svg'
            })
          "
          alt="Imagen de avatar"
          class="app-profile-picture"
        />

        <div class="btn-secondary main-points">
          <p>{{ roundNumber(user.points) }}</p>
          <img src="assets/svg/sun.svg" alt="" />
        </div>
      </div>
      <div class="data-header">
        <div class="member-name-header">{{ user.nickname }}</div>
        <div class="team-center-header">
          <p class="team-name-header">{{ user.team.name }}</p>
          <p class="center-name-header">{{ user.center.name }}</p>
        </div>
        <app-button
          [text]="'EDITAR PERFIL'"
          [classBtn]="'btn-secondary btn-profile'"
          (onClick)="toggleProfileEditor()"
        />
      </div>
    </div>

    <app-round-card
      label="MI EQUIPO"
      [buttonLabel]="'INVITAR A JUGADORES'"
      (onClickButton)="share()"
      classCard="profile-container-card"
    >
      <div class="content-card">
        <!-- Header card -->
        <div class="team">
          <div class="logo">
            <img
              [src]="'assets/logos/' + team.avatar + '.svg'"
              alt="Imagen de avatar "
            />
          </div>
          <div class="data">
            <p class="team-name">{{ team.name }}</p>
            <p class="center-name">{{ user.center.name }}</p>

            <div class="btn-secondary points">
              <p>{{ roundNumber(team.points) }}</p>
              <img src="assets/svg/sun.svg" alt="" />
            </div>
          </div>
        </div>

        <!-- Member list -->
        <div class="members-list">
          @for (member of usersToPaginator[paginationStep]; track $index) {
          <div class="member">
            <img
              [src]="
                getUserPictureSrc({
                  userId: member.id,
                  hasPicture: member.picture,
                  fallbackUrl: '../../assets/avatars/' + member.avatar + '.svg'
                })
              "
              class="app-profile-picture"
              alt="Imagen de avatar "
            />
            <div class="member-name">{{ member.nickname }}</div>
            <div class="btn-secondary points">
              <p>{{ roundNumber(member.points) }}</p>
              <img src="./assets/svg/sun.svg" alt="" />
            </div>
          </div>
          }
        </div>

        <div>
          <!-- Buttons navigation -->
          <div class="btn-navigation">
            <img
              class="btn-icon {{ paginationStep === 0 ? 'disabled' : '' }}"
              src="./assets/svg/icon-back.svg"
              alt=""
              (click)="handleClickBackStep()"
            />

            <img
              class="btn-icon {{
                paginationStep === usersToPaginator.length - 1 ? 'disabled' : ''
              }}"
              src="./assets/svg/icon-next.svg"
              alt=""
              (click)="handleClickNextStep()"
            />
          </div>
        </div>
      </div>
    </app-round-card>
    }
  </section>
  }
</app-layout>
