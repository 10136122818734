import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { ButtonsComponent } from '../../components/ui/buttons/buttons.component';
import { RoundCardComponent } from '../../components/round-card/round-card.component';
import type { Round, ScoreInfo } from 'types/round';
import { AuthService } from 'app/auth/auth.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-results',
  standalone: true,
  templateUrl: './results.component.html',
  styleUrl: './results.component.scss',
  imports: [ButtonsComponent, RoundCardComponent],
})
export class ResultsComponent implements OnInit {
  userLoading: boolean = false;
  isIndividualTeam: boolean = false;
  hasBonusPoints: boolean = false;

  scoreInfo = {
    team: {
      avatar: 0,
      points: 0,
    },
    user: {
      avatar: 0,
      points: 0,
      picture: false,
      id: 0,
    },
  };

  @Input() round: Round | null = null;
  @Input() hasCorrectAnswer: boolean = false;
  @Input() hasBoosterRound: boolean = false;
  @Input() isInBoosterRoundresults: boolean = false;
  @Input() userChoice: any = null;
  @Input() roundNumber: number = 0;
  @Input() normalRoundNumber: number = 0;
  @Input() boosterRoundNumber: number = 0;
  @Output() formEvent: EventEmitter<any> = new EventEmitter();

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    const totalUserPoints =
      (this.userChoice?.points || 0) +
      (this.userChoice?.mondayBonus
        ? this.userChoice?.mondayBonus
        : this.userChoice?.speedBonus || 0) +
      (this.userChoice?.teamBonus || 0);

    this.hasBonusPoints = Boolean(
      this.userChoice?.mondayBonus ||
        this.userChoice?.speedBonus ||
        this.userChoice?.teamBonus
    );

    if (this.authService.userInfo) {
      this.scoreInfo = {
        user: {
          avatar: this.authService.userInfo.avatar,
          points: totalUserPoints,
          picture: this.authService.userInfo.picture,
          id: this.authService.userInfo.id,
        },
        team: {
          avatar: this.authService.userInfo.team.avatar,
          points: this.userChoice?.teamPoints || 0,
        },
      };
      this.isIndividualTeam = this.authService.userInfo.team.userCount === 1;
    } else {
      this.userLoading = true;
      this.authService.getUserInfo().subscribe(({ user }: any) => {
        this.scoreInfo = {
          user: {
            avatar: user.avatar,
            points: totalUserPoints,
            picture: user.picture,
            id: user.id,
          },
          team: {
            avatar: user.team.avatar,
            points: this.userChoice?.teamPoints || 0,
          },
        };
        this.isIndividualTeam = user.team.userCount === 1;
        this.userLoading = false;
      });
    }
  }

  handleClick() {
    this.formEvent.emit({ finalStep: true });
  }

  getProfileImageSrc() {
    if (this.scoreInfo.user.picture)
      return `${environment.apiUrl}/user/picture/${this.scoreInfo.user.id}`;
    return `../../../assets/avatars/${this.scoreInfo.user.avatar}.svg`;
  }

  roundedNumber(num: number) {
    return Math.round(num);
  }

  getResumeLabel() {
    if (this.isInBoosterRoundresults)
      return `RESUMEN DE LA BOOSTER ROUND ${this.boosterRoundNumber}`;

    return `RESUMEN DE LA RONDA ${this.roundNumber}`;
  }
}
