import { Component, Input, OnInit, ViewRef } from '@angular/core';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { interval, map } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';
import { getDateTime } from 'utils';

@Component({
  selector: 'app-countdown',
  standalone: true,
  imports: [InputSwitchModule, FormsModule],
  templateUrl: './countdown.component.html',
  styleUrl: './countdown.component.scss',
})
export class CountdownComponent implements OnInit {
  @Input({ required: true }) date: Date = new Date();
  @Input() label: string = '';
  @Input() loading: boolean = false;

  counterTimer$: any = null;

  countdown!: {
    days: {
      firstDigit: number;
      secondDigit: number;
    };
    hours: {
      firstDigit: number;
      secondDigit: number;
    };
    minutes: {
      firstDigit: number;
      secondDigit: number;
    };
  };

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {
    this.parseDate();

    this.counterTimer$ = this.start().subscribe((_) => {
      if (!(this.changeDetector as ViewRef).destroyed) {
        this.changeDetector.detectChanges();
      }
      if (
        this.countdown.days.firstDigit < 0 &&
        this.countdown.days.secondDigit < 0
      ) {
        this.countdown = {
          days: {
            firstDigit: 0,
            secondDigit: 0,
          },
          hours: {
            firstDigit: 0,
            secondDigit: 0,
          },
          minutes: {
            firstDigit: 0,
            secondDigit: 0,
          },
        };
        this.counterTimer$.unsubscribe();
      }
      this.changeDetector.detectChanges();
    });
  }

  ngOnDestroy() {
    this.counterTimer$.unsubscribe();
  }

  parseDate() {
    this.countdown = {
      days: {
        firstDigit: 0,
        secondDigit: 0,
      },
      hours: {
        firstDigit: 0,
        secondDigit: 0,
      },
      minutes: {
        firstDigit: 0,
        secondDigit: 0,
      },
    };

    const difference = getDateTime(this.date);

    if (
      Number(difference.days) <= 0 &&
      Number(difference.hours) <= 0 &&
      Number(difference.minutes) <= 0
    ) {
      window.location.reload();
      return;
    }

    this.countdown.days.firstDigit = Number(difference.days[0]);
    this.countdown.days.secondDigit = Number(difference.days[1]);

    this.countdown.hours.firstDigit = Number(difference.hours[0]);
    this.countdown.hours.secondDigit = Number(difference.hours[1]);

    this.countdown.minutes.firstDigit = Number(difference.minutes[0]);
    this.countdown.minutes.secondDigit = Number(difference.minutes[1]);
  }

  start() {
    return interval(60000).pipe(
      map((x: number) => {
        this.parseDate();
        return x;
      })
    );
  }
}
