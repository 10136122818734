<div class="blue-card">
  <h3 class="subtitle-card">Comodines</h3>

  <p>
    Si te has atascado en una pregunta, <br />
    puedes ayudarte con uno de estos 5 comodines. ¡Cada uno solo se puede <br />
    usar una vez!
  </p>

  <div class="step">
    <img src="../../../../assets/svg/icon-percent.svg" alt="Icono porcentaje" />

    <h4 class="subtitle-yellow">2 Comodines de 50/50</h4>
    <p>Eliminan un 50% de respuestas erróneas</p>
    <br />
    <img src="../../../../assets/svg/icon-phone.svg" alt="Icono teléfono" />

    <h4 class="subtitle-yellow">3 Comodines del Delegado</h4>
    <p>
      Permite pedirle una pista a tu delegado (solo disponible en algunas
      preguntas especiales)
    </p>
  </div>

  <h3 class="subtitle-card">Resultados</h3>
  <div class="step">
    <img src="../../../../assets/svg/icon-bars.svg" alt="Icono podio" />

    <p>Los viernes a las 12h:</p>
    <br />
    <div class="list">
      <p><b>Se acabará el plazo</b> para esa ronda</p>

      <img src="../../../../assets/svg/icon-dot.svg" alt="Icono punto" />

      <p>Se desvelarán las <b>respuestas correctas.</b></p>

      <img src="../../../../assets/svg/icon-dot.svg" alt="Icono punto" />

      <p>Se actualizarán los <b>rankings.</b></p>
    </div>
  </div>
</div>
