<section>
  <app-round-card
    label="¡Gracias por participar!"
    [buttonLabel]="'Cerrar'"
    (onClickButton)="handleClick()"
  >
    <div class="content">
      <p>
        El viernes a las 12:00h se publicarán las respuestas correctas. ¡Mucha
        suerte!
      </p>
    </div>
  </app-round-card>
  
  <img src="../../../assets/logo-anthelios.svg" alt="">
</section>
