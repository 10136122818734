export const environment = {
  production: false,
  apiUrl: 'https://pre.antheliosleague.com:4551',

  firebaseConfig: {
    apiKey: 'AIzaSyCKOZ5tSeHsN_zkgLJssDzTi56udnrNzHg',
    authDomain: 'pre--anthelios-league.firebaseapp.com',
    projectId: 'pre--anthelios-league',
    storageBucket: 'pre--anthelios-league.appspot.com',
    messagingSenderId: '756254761489', // dev -> tambien cambiar en archivo: manifest -> "gcm_sender_id": "756254761489"
    appId: '1:756254761489:web:af52ca15e744ee503947b0',
    measurementId: 'G-262KLZS314',
    vapidKey:
      'BFzi5umwMsTh8A-JebPFqhBoMJSphOTJw42JTZCKs7TTrICTcCLPWvqWtlIrTLfxrfxFCBeStF0VtgeNOhx2qiM',
  },
};
