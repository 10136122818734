import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from 'firebase/messaging';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'app/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  public messaging = getMessaging();
  public sub: Subject<any> = new Subject();
  public notification: Observable<any> = this.sub.asObservable();

  private BASE_URL: string = environment.apiUrl;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}

  requestPermission() {
    if (localStorage.getItem('fcmToken')) return;

    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        getToken(this.messaging, {
          vapidKey: environment.firebaseConfig.vapidKey,
        })
          .then(async (currentToken) => {
            if (currentToken) {
              this.addTokenToDB(currentToken);
            } else {
              console.log(
                'No registration token available. Request permission to generate one.'
              );
            }
          })
          .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          });
      }
    });
  }

  watchMessages() {
    onMessage(this.messaging, (notification) => {
      this.sub.next(notification);
    });
  }

  async addTokenToDB(token: string) {
    localStorage.setItem('fcmToken', token);
    this.postTokenToServer(token).subscribe();
  }

  getSubscription() {
    if (!navigator) return;

    return navigator.serviceWorker.getRegistrations().then((registrations) => {
      const firebaseSWs = registrations.filter((sw) => {
        return (
          sw.active && sw.active.scriptURL.includes('firebase-messaging-sw')
        );
      });

      return firebaseSWs[0].pushManager.getSubscription();
    });
  }

  postTokenToServer(token: string) {
    const url = `${this.BASE_URL}/user/addNotificationToken`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.authService.token}`,
    });

    return this.httpClient.post(
      url,
      { pushToken: token },
      { headers: headers }
    );
  }
}
