<section class="container">
  <article
    [class]="!isHomePath ? 'score-card-main' : 'score-card-main ranking-card'"
  >
    <picture class="picture-container">
      <img [src]="imageUrl" alt="picture" />
      <span class="team-name">{{ labelPrimary }}</span>
    </picture>

    <div class="label-secondary">
      <span class="level-second">{{ labelSecondary }}</span>
    </div>

    <div class="score-container">
      <span class="score">{{ score }}</span>
      <img src="assets/svg/sun.svg" alt="" />
    </div>
  </article>
</section>
