import { Component } from '@angular/core';

@Component({
  selector: 'app-card-comodines',
  standalone: true,
  imports: [],
  templateUrl: './card-comodines.component.html',
  styleUrl: './card-comodines.component.scss'
})
export class CardComodinesComponent {

}
