import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'score-card',
  standalone: true,
  imports: [],
  templateUrl: './score-card.component.html',
  styleUrl: './score-card.component.scss',
})
export class ScoreCardComponent implements OnInit {
  @Input({ required: true }) score: number = 0;
  @Input() name: string = '';
  @Input() labelPrimary: string = '';
  @Input() labelSecondary: string = '';
  @Input({ required: true }) imageUrl: string = '';
  isHomePath: boolean = true;

  constructor(private readonly router: Router) {
    this.isHomePath = this.router.url === '/home';
  }

  ngOnInit(): void {
    this.score = Math.round(this.score);
  }
}
