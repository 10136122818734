import { Component, OnInit } from '@angular/core';
import { AppLayoutComponent } from 'app/components/app-layout/app-layout.component';
import { Router, ActivatedRoute } from '@angular/router';
import { InitialStepComponent } from './initial-step/initial-step.component';
import { FinalStepComponent } from './final-step/final-step.component';
import { InfoStepComponent } from './info-step/info-step.component';
import { QuestionStepComponent } from './question-step/question-step.component';
import { DialogModule } from 'primeng/dialog';
import { ButtonsComponent } from '../components/ui/buttons/buttons.component';
import { RoundService } from './round.service';
import { getFromStorage, setToStorage } from 'utils/storage';
import { DelegateAutocompleteComponent } from 'app/components/delegate-autocomplete/delegate-autocomplete.component';
import type { RoundResponse, DelegateOption } from 'types/round';

@Component({
  selector: 'app-round',
  standalone: true,
  templateUrl: './round.component.html',
  styleUrl: './round.component.scss',
  imports: [
    AppLayoutComponent,
    QuestionStepComponent,
    InfoStepComponent,
    FinalStepComponent,
    InitialStepComponent,
    ButtonsComponent,
    DialogModule,
    DelegateAutocompleteComponent,
  ],
})
export class RoundComponent implements OnInit {
  isOpenModal: boolean = false;
  comodinType: string = '';
  hasComodin: boolean = true;
  isComodinVisible: boolean = false;
  step: number = 1;
  currentRound: RoundResponse = {} as RoundResponse;
  loadingComodin: boolean = false;
  selectedDelegate: DelegateOption | null = null;
  errorComodin: boolean = false;

  isBoosterRound: boolean = false;

  constructor(
    private router: Router,
    private roundService: RoundService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const boosterParam = this.route.snapshot.queryParamMap.get('booster');
    if (boosterParam) this.isBoosterRound = true;

    if (this.roundService.currentRound?.status) {
      if (
        this.roundService.currentRound.status === 'answered' &&
        !boosterParam
      ) {
        this.router.navigate(['/home']);
        return;
      }

      this.currentRound = this.roundService.currentRound;

      const hasQuestionStorage = Boolean(
        getFromStorage('usedComodin')?.usedComodin?.length
      );

      if (hasQuestionStorage) {
        this.currentRound.round.roundCase.question.options =
          getFromStorage('usedComodin').usedComodin;
        this.hasComodin = false;
      }
    }

    this.roundService.getCurrentRound().subscribe(({ response, code }: any) => {
      if (
        (!boosterParam && response.status === 'answered') ||
        response.nextRoundStartsAt
      ) {
        this.router.navigate(['/home']);
        return;
      }

      console.log('🚀 ~ response:', response);
      this.currentRound = response;

      const hasQuestionStorage = Boolean(
        getFromStorage('usedComodin')?.usedComodin?.length
      );

      if (hasQuestionStorage) {
        this.currentRound.round.roundCase.question.options =
          getFromStorage('usedComodin').usedComodin;
        this.hasComodin = false;
      }

      switch (response.round.roundCase.question.comodinType) {
        case '50_50':
          {
            if (this.currentRound.remainsComodins['50_50'] > 0)
              this.comodinType = '50_50';
            else this.hasComodin = false;
          }
          break;
        default:
          this.hasComodin = false;
          break;
      }
    });
  }

  onSelectDelegate(event: any) {
    this.selectedDelegate = event;
  }

  nextStep() {
    this.step++;
  }

  handleClickBackEvent() {
    this.isComodinVisible = false;
    if (this.step === 1 || this.step === 4) this.router.navigate(['/home']);
    else this.step--;
  }

  handleFormEvent(event: any) {
    if (this.step === 4) {
      this.router.navigate(['/home']);
      return;
    }
    if (this.step === 3) this.isComodinVisible = false;

    if (this.step === 2 && !this.isBoosterRound && this.comodinType === '50_50')
      this.isComodinVisible = true;

    this.nextStep();
  }

  toggleModal() {
    this.isOpenModal = !this.isOpenModal;
    this.selectedDelegate = null;
  }

  handleComodin() {
    this.loadingComodin = true;

    this.roundService.getComodin().subscribe(({ response, code }: any) => {
      if (code === 'error') {
        this.loadingComodin = false;
        this.comodinType = '';
        this.isOpenModal = false;
        this.errorComodin = true;
        return;
      }

      if (response.options.length > 0) {
        this.currentRound.round.roundCase.question.options = response.options;
        this.toggleModal();
        setToStorage('usedComodin', response.options);
      }
      this.loadingComodin = false;
      this.hasComodin = false;
      this.comodinType = '';
      this.isOpenModal = false;
    });
  }

  toggleErrorComodin() {
    this.errorComodin = !this.errorComodin;
  }
}
