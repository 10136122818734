<div class="blue-card">
  <h3 class="subtitle-card">¿Qué es?</h3>
  <p class="text-main">
    Es la Competición por equipos de <br />
    Casos Clínicos más retadora en <br />
    fotoprotección y mucho más.
  </p>

  <h3 class="subtitle-card">¿CÓMO SE JUEGA?</h3>
  <div class="step">
    <img src="../../../../assets/svg/icon-teams.svg" alt="Icono equipos" />

    <p>
      Podréis competir en equipos <br />
      de entre <b>2 y 15 miembros.</b>
    </p>
  </div>
  <div class="step">
    <img
      src="../../../../assets/svg/icon-calendary.svg"
      alt="Icono calendario"
    />

    <p>La competición durará <b>12 semanas.</b></p>
  </div>
  <div class="step">
    <img src="../../../../assets/svg/icon-clock.svg" alt="Icono reloj" />

    <p>
      <b>Cada lunes, a las 8h,</b> <br />
      comenzará una nueva ronda.
    </p>
  </div>
  <div class="step">
    <img src="../../../../assets/svg/icon-ok.svg" alt="Icono ok" />

    <p>
      Cada miembro del equipo <br />
      <b>responde individualmente</b> a una <br />
      o más preguntas tipo test.
    </p>
  </div>
</div>
