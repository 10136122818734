<p-button
  styleClass="{{ classBtn }}"
  class="{{ loading ? 'loading' : '' }}"
  label="{{ text }}"
  [rounded]="true"
  (onClick)="handleClick()"
  [disabled]="disable"
  [loading]="loading"
  [icon]="icon"
  [iconPos]="iconPos"
></p-button>
