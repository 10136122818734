<app-round-card
  label="EXPLICACIÓN"
  [buttonLabel]="hasQueryParam ? 'Siguiente' : 'Volver'"
  (onClickButton)="hasQueryParam ? handleClick() : handleFinish()"
  [classCard]="'container-cards'"
>
  <div class="content-explanation">
    <p [innerHtml]="round?.roundCase?.question?.explanation"></p>
  </div>
</app-round-card>
