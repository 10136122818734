import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AutocompleteComponent } from '../ui/autocomplete/autocomplete.component';
import { ButtonsComponent } from '../ui/buttons/buttons.component';
import { AuthLayoutComponent } from '../auth-layout/auth-layout.component';
import { InputComponent } from '../ui/input/input.component';
import { SelectComponent } from '../ui/select/select.component';
import { DialogModule } from 'primeng/dialog';
import { environment } from 'environments/environment';
import { ImageComponent } from '../ui/image/image.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AuthService } from 'app/auth/auth.service';
import { removeStorage } from 'utils/storage';

@Component({
  selector: 'profile-editor',
  standalone: true,
  imports: [
    AutocompleteComponent,
    ButtonsComponent,
    AuthLayoutComponent,
    InputComponent,
    SelectComponent,
    ReactiveFormsModule,
    DialogModule,
    ImageComponent,
    ProgressSpinnerModule,
  ],
  templateUrl: './profile-editor.component.html',
  styleUrl: './profile-editor.component.scss',
})
export class ProfileEditorComponent {
  baseForm: FormGroup = new FormGroup({});
  @Input() user: any = null;
  currentUser: any = null;
  confirmationModal: boolean = false;
  loading: boolean = false;
  loadingchanges: boolean = false;

  imagePreviewSrc: any = null;
  loadingImageUpload: boolean = false;
  errorImageUpload: boolean = false;

  positions = [
    { name: 'Residente', code: 'RE' },
    { name: 'Adjunto', code: 'AD' },
    { name: 'Jefe de Servicio', code: 'JDS' },
  ];

  constructor(
    private readonly router: Router,
    private fb: FormBuilder,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.baseForm = this.fb.group({
      fullname: [this.user.fullname, [Validators.minLength(3)]],
      nickname: [this.user.nickname, [Validators.minLength(3)]],
      password: ['', [Validators.maxLength(30)]],
      position: [this.user.position, []],
    });
  }

  getInitialPosition() {
    const findedPositionByCode = this.positions.find(
      (position) => position.code === this.user.position
    );

    const findedPositionByName = this.positions.find(
      (position) => position.name === this.user.position
    );

    return findedPositionByName || findedPositionByCode;
  }

  handleSelectPosition(event: any) {
    this.baseForm.get('position')?.setValue(event.name);
  }

  handleSubmit() {
    this.loadingchanges = true;

    const formData = {
      fullname: this.baseForm.get('fullname')?.value,
      nickname: this.baseForm.get('nickname')?.value,
      password: this.baseForm.get('password')?.value,
      position: this.baseForm.get('position')?.value,
    };

    this.updateUserInfo(formData);
  }

  updateUserInfo(data: any) {
    this.authService.updateUserInfo(data).subscribe((response: any) => {
      window.location.reload();
    });
  }

  toggleConfirmationModal() {
    this.confirmationModal = !this.confirmationModal;
  }

  confirmDelete() {
    this.loading = true;

    this.authService.deleteAccount().subscribe((response: any) => {
      this.authService.internalLogout();
      removeStorage('onboardingSeen');
      removeStorage('fcmToken');
      removeStorage('answeredModal');
      removeStorage('notificationsModal');
      this.loading = false;
      this.router.navigate(['/']);
      this.toggleConfirmationModal();
    });
  }

  handleUploadFile(event: any) {
    this.loadingImageUpload = true;
    const file = event.target.files[0];
    const formData = new FormData();

    formData.append('file', file);
    formData.append('originalName', file?.name || '');
    formData.append('imageType', file?.type || '');

    this.imagePreviewSrc = URL.createObjectURL(file);

    const boundary =
      '---------------------------' +
      Math.floor(Math.random() * 10e16).toString(32);

    this.authService
      .postUserPicture({ formData, boundary })
      .subscribe((response) => {
        if (response?.code === 'error') {
          this.errorImageUpload = true;
          this.imagePreviewSrc = null;
        }

        this.loadingImageUpload = false;

        window.location.reload();
      });
  }

  getProfileImageSrc() {
    if (this.user.picture && !this.imagePreviewSrc) {
      return `${environment.apiUrl}/user/picture/${this.user.id}`;
    }

    if (this.imagePreviewSrc) return this.imagePreviewSrc;
    return `../../../assets/avatars/${this.user.avatar}.svg`;
  }

  toggleErrorModal() {
    this.errorImageUpload = !this.errorImageUpload;
    this.imagePreviewSrc = null;
  }

  handleOpenInputFile() {
    this.imagePreviewSrc = null;
    document.getElementById('file-ip-1')?.click();
    this.errorImageUpload = false;
  }
}
