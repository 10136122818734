<auth-layout>
  <div class="formBox">
    <h1 class="title-main">SOLICITAR CAMBIO DE CONTRASEÑA</h1>

    @if(sendedEmail){
    <div class="feedback-container">
      <p>
        Hemos enviado un correo electrónico a tu dirección "{{ email }}" con una
        contraseña nueva para que puedas acceder.
      </p>
      <a routerLink="/login">
        <app-button text="volver" [classBtn]="'btn-secondary'" />
      </a>
    </div>
    } @else{
    <div class="input-container">
      <app-input
        placeholder="e-mail"
        (input)="onChangeEmail($event)"
        [errorMessage]="errorMessage"
      />
    </div>
    <div class="button-container">
      <app-button
        [text]="isLoading ? 'Solicitando' : 'Solicitar'"
        [classBtn]="'btn-primary'"
        (onClick)="onSubmit()"
        [loading]="isLoading"
        [disable]="!isValidEmail"
      />
      <a routerLink="/login">
        <app-button text="Cancelar" [classBtn]="'btn-secondary'" />
      </a>
    </div>
    }
  </div>
</auth-layout>
