<app-layout title="Premios" (formEvent)="handleClickBack()">
  <div class="container-list">
    <h3 class="awards-title">
      PREMIOS PARA LOS <br />
      3 MEJORES EQUIPOS
    </h3>

    <div class="round-card">
      <img src="../../assets/svg/icon-medal.svg" alt="" />
      <div class="awards-container">
        <p class="details">Experiencia <b>MotoGP</b></p>
        <p class="details">
          <b>Valencia 2024</b>
        </p>
      </div>
    </div>

    <div class="round-card">
      <img src="../../assets/svg/icon-medal-2.svg" alt="" />
      <div class="awards-container">
        <p class="details">Experiencia <b>Final</b></p>
        <p class="details">
          <b>Premier Pádel 2024</b>
        </p>
      </div>
    </div>

    <div class="round-card">
      <img src="../../assets/svg/icon-medal-3.svg" alt="" />
      <div class="awards-container">
        <p class="details">Experiencia <b>Semis</b></p>
        <p class="details">
          <b>Premier Pádel 2024</b>
        </p>
      </div>
    </div>
  </div>

  <div class="container-list">
    <h3 class="awards-title">
      PREMIOS PARA LOS <br />
      3 MEJORES JUGADORES
    </h3>

    <div class="round-card">
      <img src="../../assets/svg/icon-medal.svg" alt="" />
      <div class="awards-container">
        <p class="details">Inscripción y viaje al</p>
        <p class="details">
          <b>Congreso EADV 2024</b>
        </p>
      </div>
    </div>

    <div class="round-card">
      <img src="../../assets/svg/icon-medal-2.svg" alt="" />
      <div class="awards-container">
        <p class="details">Inscripción y viaje al</p>
        <p class="details">
          <b>Congreso GEDET 2024</b>
        </p>
      </div>
    </div>

    <div class="round-card">
      <img src="../../assets/svg/icon-medal-3.svg" alt="" />
      <div class="awards-container">
        <p class="details">Inscripción y viaje al</p>
        <p class="details">
          <b>Congreso GEDOC 2024</b>
        </p>
      </div>
    </div>
  </div>

  <div class="container-btns">
    <a
      href="https://www.antheliosleague.com/terminosycondiciones.pdf"
      target="“_blank”"
    >
      <app-button
        text="VER BASES LEGALES"
        [classBtn]="'btn-secondary legal-awards'"
      />
    </a>
  </div>
</app-layout>
