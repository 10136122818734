<section class="container">
  <div class="content">
    <a routerLink="/profile" (click)="handleSelectItem()">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
        >
          <path
            d="M29.5191 30.3186C28.8544 28.458 27.3897 26.814 25.3522 25.6414C23.3147 24.4688 20.8182 23.8333 18.25 23.8333C15.6818 23.8333 13.1853 24.4688 11.1478 25.6414C9.11026 26.814 7.64557 28.458 6.98086 30.3186"
            stroke="#FFEC2D"
            stroke-width="3"
            stroke-linecap="round"
          />
          <circle
            cx="18.25"
            cy="12.1666"
            r="5.83333"
            stroke="#FFEC2D"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <span>mi perfil</span>
    </a>
    <a routerLink="/rankings" (click)="handleSelectItem()">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="22"
          viewBox="0 0 33 22"
          fill="none"
        >
          <rect
            x="2.33334"
            y="10.2896"
            width="6.66667"
            height="9.64403"
            rx="1"
            stroke="#FFEC2D"
            stroke-width="3"
            stroke-linecap="round"
          />
          <rect
            x="13.6666"
            y="2.06641"
            width="6.66667"
            height="17.8672"
            rx="1"
            stroke="#FFEC2D"
            stroke-width="3"
            stroke-linecap="round"
          />
          <rect
            x="24.3333"
            y="13.752"
            width="6.66667"
            height="6.18162"
            rx="1"
            stroke="#FFEC2D"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <span>rankings</span>
    </a>
    <a href="/#/rounds" (click)="handleSelectItem('archivo')">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
        >
          <path
            d="M26.2113 4.43457V4.43457C27.097 4.43457 27.5398 4.43457 27.8863 4.58701C28.2984 4.76834 28.6276 5.09752 28.8089 5.50963C28.9613 5.85607 28.9613 6.2989 28.9613 7.18457V23.5546C28.9613 24.5627 28.9613 25.0667 28.7651 25.4518C28.5926 25.7904 28.3172 26.0658 27.9785 26.2384C27.5935 26.4346 27.0894 26.4346 26.0813 26.4346H7.09133C6.08324 26.4346 5.57919 26.4346 5.19415 26.2384C4.85546 26.0658 4.58009 25.7904 4.40752 25.4518C4.21133 25.0667 4.21133 24.5627 4.21133 23.5546V7.18457C4.21133 6.2989 4.21133 5.85607 4.36377 5.50963C4.5451 5.09752 4.87429 4.76834 5.28639 4.58701C5.63283 4.43457 6.07567 4.43457 6.96133 4.43457V4.43457"
            stroke="#FFEC2D"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M26.2113 4.43457H24.6974C24.096 4.43457 23.7953 4.43457 23.5536 4.58392C23.312 4.73327 23.1775 5.00223 22.9085 5.54014L22.6391 6.079C22.3702 6.61691 22.2357 6.88587 21.994 7.03522C21.7524 7.18457 21.4517 7.18457 20.8503 7.18457H12.1905C11.5442 7.18457 11.2211 7.18457 10.9691 7.01662C10.7172 6.84867 10.5929 6.55038 10.3443 5.9538L10.2242 5.66534C9.97558 5.06876 9.85129 4.77047 9.59937 4.60252C9.34744 4.43457 9.0243 4.43457 8.378 4.43457H6.96133"
            stroke="#FFEC2D"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M28.9613 11.3096H24.6974C24.096 11.3096 23.7953 11.3096 23.5536 11.4589C23.312 11.6083 23.1775 11.8772 22.9085 12.4151L22.6391 12.954C22.3702 13.4919 22.2357 13.7609 21.994 13.9102C21.7524 14.0596 21.4517 14.0596 20.8503 14.0596H12.1905C11.5442 14.0596 11.2211 14.0596 10.9691 13.8916C10.7172 13.7237 10.5929 13.4254 10.3443 12.8288L10.2242 12.5403C9.97558 11.9438 9.85129 11.6455 9.59937 11.4775C9.34744 11.3096 9.0243 11.3096 8.378 11.3096H4.21133"
            stroke="#FFEC2D"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M28.9613 18.1846H24.6974C24.096 18.1846 23.7953 18.1846 23.5536 18.3339C23.312 18.4833 23.1775 18.7522 22.9085 19.2901L22.6391 19.829C22.3702 20.3669 22.2357 20.6359 21.994 20.7852C21.7524 20.9346 21.4517 20.9346 20.8503 20.9346H12.1905C11.5442 20.9346 11.2211 20.9346 10.9691 20.7666C10.7172 20.5987 10.5929 20.3004 10.3443 19.7038L10.2242 19.4153C9.97558 18.8188 9.85129 18.5205 9.59937 18.3525C9.34744 18.1846 9.0243 18.1846 8.378 18.1846H4.21133"
            stroke="#FFEC2D"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <span>archivo</span>
    </a>
    <a routerLink="/centers" (click)="handleSelectItem()">
      <div>
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.625 9.74463L18.125 9.74463"
            stroke="#FFEC2D"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M12.625 20.7446L16.75 20.7446"
            stroke="#FFEC2D"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M12.625 15.2446L20.875 15.2446"
            stroke="#FFEC2D"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M26.375 15.2446V10.2446C26.375 7.4162 26.375 6.00199 25.4963 5.12331C24.6176 4.24463 23.2034 4.24463 20.375 4.24463H13.125C10.2966 4.24463 8.88236 4.24463 8.00368 5.12331C7.125 6.00199 7.125 7.4162 7.125 10.2446V22.9946C7.125 25.8231 7.125 27.2373 8.00368 28.1159C8.88236 28.9946 10.2966 28.9946 13.125 28.9946H16.75"
            stroke="#FFEC2D"
            stroke-width="3"
          />
          <circle
            cx="24.3125"
            cy="24.1821"
            r="3.4375"
            stroke="#FFEC2D"
            stroke-width="3"
          />
          <path
            d="M29.125 28.9946L27.0625 26.9321"
            stroke="#FFEC2D"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </div>

      <span>listado de centros</span>
    </a>
    <a routerLink="/awards" (click)="handleSelectItem()">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="41"
          height="41"
          viewBox="0 0 41 41"
          fill="none"
        >
          <path
            d="M28.1875 35.021H12.8125"
            stroke="#FFEC2D"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M22 31.604C22 32.4324 21.3284 33.104 20.5 33.104C19.6716 33.104 19 32.4324 19 31.604H22ZM19 31.604V27.3332H22V31.604H19Z"
            fill="#FFEC2D"
          />
          <path
            d="M17.9375 16.229H23.0625"
            stroke="#FFEC2D"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M9.39584 24.7707C9.39584 24.7707 5.97917 22.2082 5.97917 17.9373C5.97917 16.6299 5.97917 15.4825 5.97917 14.5197C5.97917 13.1952 5.97917 12.5329 6.28077 12.0479C6.44277 11.7874 6.66252 11.5676 6.92304 11.4056C7.40804 11.104 8.07064 11.104 9.39584 11.104V11.104C10.721 11.104 11.3836 11.104 11.8686 11.4056C12.1292 11.5676 12.3489 11.7874 12.5109 12.0479C12.8125 12.5329 12.8125 13.1955 12.8125 14.5207V16.229"
            stroke="#FFEC2D"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M31.6042 24.7707C31.6042 24.7707 35.0208 22.2082 35.0208 17.9373C35.0208 16.6299 35.0208 15.4825 35.0208 14.5197C35.0208 13.1952 35.0208 12.5329 34.7192 12.0479C34.5572 11.7874 34.3375 11.5676 34.077 11.4056C33.592 11.104 32.9294 11.104 31.6042 11.104V11.104C30.279 11.104 29.6164 11.104 29.1314 11.4056C28.8709 11.5676 28.6511 11.7874 28.4891 12.0479C28.1875 12.5329 28.1875 13.1955 28.1875 14.5207V16.229"
            stroke="#FFEC2D"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M28.1875 20.9219V11.396C28.1875 10.2914 27.2921 9.396 26.1875 9.396H14.8125C13.7079 9.396 12.8125 10.2914 12.8125 11.396V20.9219C12.8125 22.2593 13.4809 23.5083 14.5937 24.2501L19.9453 27.8179C20.2812 28.0418 20.7188 28.0418 21.0547 27.8179L26.4063 24.2501C27.5191 23.5083 28.1875 22.2593 28.1875 20.9219Z"
            stroke="#FFEC2D"
            stroke-width="3"
          />
        </svg>
      </div>
      <span>premios</span>
    </a>
    <a routerLink="/instructions" (click)="handleSelectItem()">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
        >
          <path
            d="M16.0625 18.1533H18.25V23.9867H21.1667M18.25 12.32H18.2646M20.5291 31.0789C22.2265 30.7796 23.8484 30.1489 25.3021 29.2228C26.7557 28.2967 28.0128 27.0934 29.0014 25.6815C29.99 24.2696 30.6908 22.6768 31.0639 20.9941C31.4369 19.3113 31.4749 17.5716 31.1756 15.8742C30.8763 14.1768 30.2456 12.5549 29.3195 11.1013C28.3934 9.64759 27.1901 8.39057 25.7782 7.40195C24.3663 6.41333 22.7735 5.71249 21.0908 5.33943C19.408 4.96638 17.6683 4.92842 15.9709 5.22772C14.2735 5.52702 12.6516 6.15772 11.1979 7.08381C9.74427 8.0099 8.48724 9.21324 7.49863 10.6251C6.51001 12.037 5.80917 13.6298 5.43611 15.3126C5.06306 16.9953 5.0251 18.735 5.3244 20.4325C5.6237 22.1299 6.2544 23.7517 7.18049 25.2054C8.10658 26.659 9.30992 27.9161 10.7218 28.9047C12.1337 29.8933 13.7265 30.5942 15.4092 30.9672C17.092 31.3403 18.8317 31.3782 20.5291 31.0789Z"
            stroke="#FFEC2D"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <span>cómo jugar</span>
    </a>
    <a
      href="https://www.antheliosleague.com/terminosycondiciones.pdf"
      target="_blank"
      (click)="handleSelectItem()"
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="26"
          viewBox="0 0 25 26"
          fill="none"
        >
          <path
            d="M1.91667 13.4033C1.91667 11.5177 1.91667 10.5749 2.50246 9.98911C3.08824 9.40332 4.03105 9.40332 5.91667 9.40332H18.5833C20.469 9.40332 21.4118 9.40332 21.9976 9.98911C22.5833 10.5749 22.5833 11.5177 22.5833 13.4033V18.4033C22.5833 21.2317 22.5833 22.646 21.7047 23.5246C20.826 24.4033 19.4118 24.4033 16.5833 24.4033H7.91667C5.08824 24.4033 3.67403 24.4033 2.79535 23.5246C1.91667 22.646 1.91667 21.2317 1.91667 18.4033V13.4033Z"
            stroke="#FFEC2D"
            stroke-width="3"
          />
          <path
            d="M17.4167 8.15332V7.06999C17.4167 6.91524 17.4167 6.83787 17.415 6.77244C17.3453 4.11362 15.2064 1.97466 12.5476 1.90503C12.4821 1.90332 12.4048 1.90332 12.25 1.90332V1.90332C12.0953 1.90332 12.0179 1.90332 11.9525 1.90503C9.29364 1.97466 7.15468 4.11362 7.08506 6.77244C7.08334 6.83787 7.08334 6.91524 7.08334 7.06999V8.15332"
            stroke="#FFEC2D"
            stroke-width="3"
            stroke-linecap="round"
          />
          <mask id="path-3-inside-1_2180_1589" fill="white">
            <ellipse cx="12.25" cy="16.9033" rx="2.58333" ry="2.5" />
          </mask>
          <ellipse
            cx="12.25"
            cy="16.9033"
            rx="2.58333"
            ry="2.5"
            fill="#33363F"
          />
          <path
            d="M11.8333 16.9033C11.8333 16.5352 12.1134 16.4033 12.25 16.4033V22.4033C15.2401 22.4033 17.8333 20.0328 17.8333 16.9033H11.8333ZM12.25 16.4033C12.3866 16.4033 12.6667 16.5352 12.6667 16.9033H6.66667C6.66667 20.0328 9.2599 22.4033 12.25 22.4033V16.4033ZM12.6667 16.9033C12.6667 17.2714 12.3866 17.4033 12.25 17.4033V11.4033C9.2599 11.4033 6.66667 13.7738 6.66667 16.9033H12.6667ZM12.25 17.4033C12.1134 17.4033 11.8333 17.2714 11.8333 16.9033H17.8333C17.8333 13.7738 15.2401 11.4033 12.25 11.4033V17.4033Z"
            fill="#FFEC2D"
            mask="url(#path-3-inside-1_2180_1589)"
          />
        </svg>
      </div>
      <span>política de privacidad</span>
    </a>
    <a (click)="handleLogout()">
      <div>
        @if(loadingLogout){
        <div class="logout-spinner">
          <p-progressSpinner
            strokeWidth="4"
            animationDuration="1s"
          ></p-progressSpinner>
        </div>
        }@else{
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M2.75 12.9033L1.5787 11.9663C1.14043 12.5141 1.14043 13.2925 1.5787 13.8404L2.75 12.9033ZM11.75 14.4033C12.5784 14.4033 13.25 13.7317 13.25 12.9033C13.25 12.0749 12.5784 11.4033 11.75 11.4033V14.4033ZM5.5787 6.96628L1.5787 11.9663L3.9213 13.8404L7.9213 8.84036L5.5787 6.96628ZM1.5787 13.8404L5.5787 18.8404L7.9213 16.9663L3.9213 11.9663L1.5787 13.8404ZM2.75 14.4033H11.75V11.4033H2.75V14.4033Z"
            fill="#FFEC2D"
          />
          <path
            d="M10.75 9.03525V8.29183C10.75 6.67349 10.75 5.86432 11.224 5.30482C11.6979 4.74533 12.4961 4.61231 14.0924 4.34625L15.7636 4.06772C19.0067 3.5272 20.6282 3.25695 21.6891 4.15564C22.75 5.05434 22.75 6.69825 22.75 9.98608V15.8206C22.75 19.1084 22.75 20.7523 21.6891 21.651C20.6282 22.5497 19.0067 22.2794 15.7636 21.7389L14.0924 21.4604C12.4961 21.1943 11.6979 21.0613 11.224 20.5018C10.75 19.9423 10.75 19.1332 10.75 17.5148V16.9693"
            stroke="#FFEC2D"
            stroke-width="3"
            stroke-linejoin="round"
          />
        </svg>
        }
      </div>
      <span>{{ loadingLogout ? "Cerrando sesión..." : "Cerrar sesión" }}</span>
    </a>
  </div>
</section>
