import { Component } from '@angular/core';

@Component({
  selector: 'app-card-booster-instructions',
  standalone: true,
  imports: [],
  templateUrl: './card-booster-instructions.component.html',
  styleUrl: './card-booster-instructions.component.scss'
})
export class CardBoosterInstructionsComponent {

}
