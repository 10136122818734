<section class="container">
  <app-round-card
    label="Caso clínico"
    [secondaryLabel]="round?.roundCase?.question?.professional || ''"
    [buttonLabel]="'Siguiente'"
    (onClickButton)="handleClick()"
    [classCard]="'container-cards'"
  >
    <div class="content">
      <p>
        {{ round?.roundCase?.description }}
      </p>

      <div class="images">
        @if(round?.roundCase?.pictures?.length){ @for (picture of
        round?.roundCase?.pictures; track picture.id; let idx = $index) {
        <app-button
          [text]="'Ver imagen ' + (idx + 1)"
          [classBtn]="'btn-secondary full-btn'"
          (onClick)="showDialog(picture.id)"
        />
        } }
      </div>
    </div>
  </app-round-card>
</section>

<p-dialog
  [(visible)]="visible"
  [breakpoints]="{ '960px': '320px' }"
  [style]="{ width: '320px', height: 'auto' }"
  [draggable]="false"
  [resizable]="false"
  [showHeader]="false"
  class="image-modal"
  [maximizable]="false"
  [showHeader]="true"
  [closable]="false"
>
  <ng-template pTemplate="header"> </ng-template>
  <img [src]="getSrcImageById()" alt="" class="banner" />

  <button class="close-btn" (click)="hideDialog()">
    <i class="pi pi-times" style="color: #cea023; font-size: 1rem"></i>
  </button>
</p-dialog>
