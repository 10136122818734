<app-layout
  [title]="isBoosterRound ? 'Booster round' : 'Nueva ronda'"
  (formEvent)="handleClickBackEvent()"
  [contentRound]="true"
>
  @switch (step) { @case (1) {
  <round-initial-step
    (formEvent)="handleFormEvent($event)"
    [currentRoundNumber]="
      isBoosterRound
        ? currentRound.boosterRound.number
        : currentRound.round.number
    "
    [isBooster]="isBoosterRound"
  />
  } @case (2) {
  <round-info-step
    (formEvent)="handleFormEvent($event)"
    [round]="isBoosterRound ? currentRound.boosterRound : currentRound.round"
  />
  } @case (3) {
  <round-question-step
    (formEvent)="handleFormEvent($event)"
    [answers]="
      isBoosterRound
        ? currentRound.boosterRound.roundCase.question.options
        : currentRound.round.roundCase.question.options
    "
    [round]="isBoosterRound ? currentRound.boosterRound : currentRound.round"
    [isBooster]="isBoosterRound"
  />
  } @case (4) {
  <round-final-step (formEvent)="handleFormEvent($event)" />
  } } @if(isComodinVisible){
  <div class="comodin-container">
    <div class="comodin-btns">
      <div class="comodin-logo" (click)="toggleModal()">
        <img src="assets/svg/comodin.svg" alt="" />
      </div>
      <app-button
        [style]="'margin-left: 50px;'"
        text="Comodines"
        [classBtn]="'btn-app btn-secondary'"
        (click)="toggleModal()"
      ></app-button>
    </div>
  </div>
  }
</app-layout>

<p-dialog
  [(visible)]="isOpenModal"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  class="comodin-modal"
  [styleClass]="'comodin-modal'"
>
  <ng-template pTemplate="headless">
    <div class="comodin-modal__header">
      <div class="comodin-logo">
        <img src="assets/svg/comodin.svg" alt="" />
      </div>
    </div>

    <div class="comodin-modal__content">
      <div class="comodin-modal__body">
        <h3>
          {{ "COMODINES" }}
        </h3>
        @if(hasComodin){
        <p>Este es el comodín disponible para esta pregunta:</p>
        } @else {
        <p>No hay comodines disponibles para esta pregunta</p>
        }
      </div>
      <div class="comodin-modal__footer">
        @if(hasComodin) {
        <app-button
          text="50/50"
          (click)="handleComodin()"
          [classBtn]="'btn-primary'"
          [loading]="loadingComodin"
        ></app-button>
        }
        <app-button
          text="Volver"
          (click)="toggleModal()"
          [classBtn]="'btn-secondary'"
        ></app-button>
      </div>
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="errorComodin"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [showHeader]="false"
  [closable]="false"
  class="delete-confirmation-modal"
>
  <div class="container">
    <h2>Ha habido un error al utilizar el comodín</h2>
    <p>Vuelve a intentarlo en unos minutos.</p>
    <div class="buttons-container">
      <app-button
        text="Cerrar"
        [classBtn]="'btn-secondary btn-dialog'"
        (onClick)="toggleErrorComodin()"
      />
    </div>
  </div>
</p-dialog>
