import { DateTime, Settings } from 'luxon';
import { environment } from 'environments/environment';

Settings.defaultZone = 'Europe/Madrid';

export const sanatize = (string: string) =>
  string
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

export const isMobile = () => {
  const ua = navigator.userAgent;
  const isAndroid = Boolean(ua.match(/Android/i));
  const isIos = Boolean(ua.match(/iPhone|iPad|iPod/i));
  const isOpera = Boolean(ua.match(/Opera Mini/i));
  const isWindows = Boolean(ua.match(/IEMobile/i));

  return Boolean(isAndroid || isIos || isOpera || isWindows);
};

export const getLetterKeyByIndex = (index: number): string =>
  String.fromCharCode(65 + index);

export const getDateTime = (endDate: Date) => {
  const spainDateTime = DateTime.now().setZone('Europe/Madrid');
  const targetTime = DateTime.fromISO(`${endDate}`).plus({
    minutes: 1,
  });

  const difference = targetTime
    .diff(spainDateTime)
    .toFormat('dd:hh:mm')
    .split(':');

  const splitted = {
    days: difference[0],
    hours: difference[1],
    minutes: difference[2],
  };

  return splitted;
};

export const getUserPictureSrc = ({
  userId,
  hasPicture,
  fallbackUrl,
}: {
  userId: number;
  hasPicture: boolean;
  fallbackUrl: string;
}) => {
  if (hasPicture) {
    return `${environment.apiUrl}/user/picture/${userId}`;
  }

  return fallbackUrl;
};

export const getBoosterRoundInfoByRound = (round: number) => {
  const boosterRoundNumberMapByRoundNumber: Record<number, number> = {
    8: 1,
    10: 2,
    12: 3,
  };
  const boosterRoundIdMapByRoundNumber: Record<number, number> = {
    8: 13,
    10: 14,
    12: 15,
  };

  return {
    id: boosterRoundIdMapByRoundNumber[round],
    number: boosterRoundNumberMapByRoundNumber[round],
  };
};
