<form
  class="container"
  [formGroup]="baseForm"
  (submit)="handleSubmit()"
  autocomplete="off"
>
  <!--**** Main avatar ****-->
  @if(loadingImageUpload){
  <div class="picture-spinner">
    <img [src]="imagePreviewSrc" alt="Imagen de avatar " />
    <p-progressSpinner
      strokeWidth="4"
      animationDuration="1s"
    ></p-progressSpinner>
  </div>
  }@else{
  <div class="main-avatar">
    <img class="avatar" [src]="getProfileImageSrc()" alt="Imagen de avatar " />

    <label for="file-ip-1" class="icon">
      <input
        type="file"
        id="file-ip-1"
        accept="image/jpeg"
        [multiple]="false"
        (change)="handleUploadFile($event)"
      />

      <img src="../../../assets/svg/Icon-camera.svg" alt="Ícono de cambio " />
    </label>
  </div>
  }

  <!--**** Inputs ****-->
  <div class="inputs-container">
    <!-- Name-Input -->
    <div class="input-profile">
      <p class="label">Nombre y Apellidos</p>
      <app-input
        placeholder="Nombre y apellidos"
        formControlName="fullname"
        [required]="true"
        [initialValue]="user.fullname"
      />
    </div>

    <!-- Email-Input -->
    <div class="input-profile">
      <p class="label">E-mail</p>
      <div class="content-input">
        <p>{{ user.email }}</p>
        <img src="../../../assets/svg/icon-padlock.svg" alt="" />
      </div>
    </div>

    <!-- Nickname-Input -->
    <div class="input-profile">
      <p class="label">Nickname</p>
      <app-input
        placeholder="Nickname"
        formControlName="nickname"
        [required]="true"
        [initialValue]="user.nickname"
      />
    </div>

    <!-- Pass-Input -->
    <div class="input-profile">
      <p class="label">Contraseña</p>
      <app-input
        placeholder="•••••••••••"
        type="password"
        formControlName="password"
        [profileClass]="false"
      />
    </div>

    <!-- Center-Input -->
    <div class="input-profile">
      <p class="label">Center</p>
      <div class="content-input">
        <p>{{ user.center.name }}</p>
        <img src="../../../assets/svg/icon-padlock.svg" alt="" />
      </div>
    </div>

    <!-- Position-Input -->
    <div class="input-profile">
      <p class="label">Cargo</p>
      <app-select
        placeholder="Cargo"
        [initialOption]="getInitialPosition()"
        [options]="positions"
        (onChangeOption)="handleSelectPosition($event)"
      />
    </div>

    <!-- Team-Input -->
    <div class="input-profile">
      <p class="label">Team</p>
      <div class="content-input">
        <p>{{ user.team.name }}</p>
        <img src="../../../assets/svg/icon-padlock.svg" alt="" />
      </div>
    </div>
  </div>

  <!--**** Query ****-->
  <div class="querys-text">
    <p>
      Si tienes alguna duda, escribe a
      <a target="_blank" href="mailto:soporte@antheliosleague.com"
        >soporte&#64;antheliosleague.com</a
      >
    </p>
  </div>

  <!--**** Buttons ****-->
  <div class="btn-container">
    <app-button
      [text]="loadingchanges ? 'GUARDANDO...' : 'GUARDAR CAMBIOS'"
      [classBtn]="'btn-primary'"
      type="submit"
      [loading]="loadingchanges"
      (onClick)="handleSubmit()"
    />
    <app-button
      [text]="'ELIMINAR CUENTA'"
      [classBtn]="'btn-secondary'"
      type="button"
      (onClick)="toggleConfirmationModal()"
    />
  </div>
</form>

<p-dialog
  [(visible)]="confirmationModal"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [showHeader]="false"
  [closable]="false"
  class="delete-confirmation-modal"
>
  <div class="container">
    <h2>¿Deseas eliminar la cuenta?</h2>

    <div class="buttons-container">
      <app-button
        text="Cancelar"
        [classBtn]="'btn-primary btn-dialog'"
        (onClick)="toggleConfirmationModal()"
      />
      <app-button
        [text]="loading ? 'Eliminando...' : 'Eliminar'"
        [classBtn]="'btn-secondary btn-dialog'"
        (onClick)="confirmDelete()"
        [loading]="loading"
      />
    </div>
  </div>
</p-dialog>

<p-dialog
  [(visible)]="errorImageUpload"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [showHeader]="false"
  [closable]="false"
  class="delete-confirmation-modal"
>
  <div class="container">
    <h2>Ha habido un error al subir tu foto.</h2>
    <p>¿Quieres volver a intentarlo?</p>

    <div class="buttons-container">
      <app-button
        text="Aceptar"
        [classBtn]="'btn-secondary btn-dialog'"
        (onClick)="handleOpenInputFile()"
      />
      <app-button
        text="Más tarde"
        [classBtn]="'btn-secondary btn-dialog'"
        (onClick)="toggleErrorModal()"
      />
    </div>
  </div>
</p-dialog>
