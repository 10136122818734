import { Component } from '@angular/core';

@Component({
  selector: 'app-card-booster',
  standalone: true,
  imports: [],
  templateUrl: './card-booster.component.html',
  styleUrl: './card-booster.component.scss'
})
export class CardBoosterComponent {

}
