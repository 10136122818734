import { Component, Input, OnInit } from '@angular/core';
import { ScoreCardComponent } from '../score-card/score-card.component';
import { ActivatedRoute } from '@angular/router';
import { RankingsService } from 'app/services/rankings/rankings.service';
import { AuthService } from 'app/auth/auth.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-ranking',
  standalone: true,
  templateUrl: './ranking.component.html',
  styleUrl: './ranking.component.scss',
  imports: [ScoreCardComponent],
})
export class RankingComponent implements OnInit {
  ranking: any[] = [];
  @Input() isTeam: boolean = true;

  instance: string = this.isTeam ? 'logos' : 'avatars';
  user: any = null;

  userData: {
    idUser: number;
    idTeam: number;
  } = {
    idUser: 0,
    idTeam: 0,
  };

  top: any[] = [];

  list: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private rankingService: RankingsService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.top = this.ranking.slice(0, 3);
    this.list = this.ranking.slice(3);
    this.getRanking();

    if (!this.authService.userInfo) {
      this.authService.getUserInfo().subscribe(({ user }: any) => {
        this.user = user;
        this.userData = {
          idUser: user.id,
          idTeam: user.team.id,
        };
      });
    } else {
      this.user = this.authService.userInfo;
      this.userData = {
        idUser: this.authService.userInfo.id,
        idTeam: this.authService.userInfo.team.id,
      };
    }
  }

  getRanking() {
    const hasExpandedClass = document
      .querySelectorAll('.table-container')
      [this.isTeam ? 0 : 1]?.classList.contains('expanded');
    if (hasExpandedClass)
      document
        .querySelectorAll('.table-container')
        [this.isTeam ? 0 : 1]?.classList.remove('expanded');

    this.rankingService
      .getRankings(this.isTeam ? 'team' : 'user')
      .subscribe((response) => {
        if (response.code === 'ok') {
          this.ranking = this.insertarPosicion(response.response) as any[];
          this.top = [this.ranking[1], this.ranking[0], this.ranking[2]];
          this.list = this.ranking.slice(3);

          this.scrollToPosition().then(() => {
            document
              .querySelectorAll('.table-container')
              [this.isTeam ? 0 : 1]?.classList.add('expanded');
          });
        }
      });
  }

  roundNumber(num: number) {
    return Math.round(num);
  }

  insertarPosicion(array: any[]) {
    let initialArray = [...array];
    for (let i = 0; i < array.length; i++) {
      initialArray[i].position = i + 1;
    }
    return initialArray;
  }

  scrollToPosition(): Promise<void> {
    return new Promise((resolve) => {
      const instance = this.isTeam ? 'team' : 'user';

      setTimeout(() => {
        const targetElement = document.querySelector(
          '.' + instance + '-position'
        );
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'instant' });
        }
        resolve();
      }, 1000);
    });
  }

  getUserPictureSrc({
    userId,
    hasPicture,
    fallbackUrl,
  }: {
    userId: number;
    hasPicture: boolean;
    fallbackUrl: string;
  }) {
    if (hasPicture) {
      return `${environment.apiUrl}/user/picture/${userId}`;
    }

    return fallbackUrl;
  }

  getPodiumScoreCardAvatarSrc(item: any) {
    if (this.isTeam) return `/assets/logos/${item.avatar}.svg`;

    if (item.picture) return `${environment.apiUrl}/user/picture/${item.id}`;

    return `assets/avatars/${item.avatar}.svg`;
  }
}
