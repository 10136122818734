<div class="ranking__container">
  <div class="ranking__podium">
    <div class="ranking__avatars">
      @for (item of top; track item.position) {
      <div class="ranking__avatars__{{ item.position }}">
        <score-card
          imageUrl="/assets/{{ isTeam ? 'logos' : 'avatars' }}/{{
            item.avatar
          }}.svg"
          [score]="item.points"
          labelPrimary="{{ isTeam ? item.name : item.nickname }}"
          labelSecondary="{{ item.center }}"
          [imageUrl]="getPodiumScoreCardAvatarSrc(item)"
        ></score-card>
      </div>
      }
    </div>
    <img
      src="{{
        isTeam
          ? './assets/svg/podium-yellow.svg'
          : './assets/svg/podium-blue.svg'
      }}"
      class=""
      alt=""
    />
    <div class="flare"></div>
  </div>
  <div class="table-container">
    <ul class="ranking__table">
      @if(isTeam){ @for (item of list; track item.position) {
      <li class="item {{ item.id === userData.idTeam ? 'team-position' : '' }}">
        <p class="position-number">{{ item.position }} |</p>
        <div class="item__info">
          <img src="/assets/logos/{{ item.avatar }}.svg" alt="avatar" />
          <div class="team-details">
            <p class="item-name">{{ item.name }}</p>
            <p class="item-center">{{ item.center }}</p>
          </div>
        </div>
        <div class="item__score">
          <span>{{ roundNumber(item.points) }}</span>
          <img src="assets/svg/sun.svg" alt="" />
        </div>
      </li>
      } } @else { @for (item of list; track item.position) {
      <li class="item {{ item.id === userData.idUser ? 'user-position' : '' }}">
        <p class="position-number">{{ item.position }} |</p>
        <div class="item__info">
          <img
            [src]="
              getUserPictureSrc({
                userId: item.id,
                hasPicture: item.picture,
                fallbackUrl: '../../assets/avatars/' + item.avatar + '.svg'
              })
            "
            class="app-profile-picture"
            alt="avatar"
          />
          <div class="team-details">
            <p class="item-name">{{ item.nickname }}</p>
            <p class="item-center">{{ item.center }}</p>
          </div>
        </div>
        <div class="item__score">
          <span>{{ roundNumber(item.points) }}</span>
          <img src="assets/svg/sun.svg" alt="" />
        </div>
      </li>
      } }
    </ul>
  </div>
</div>
