import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewRef,
} from '@angular/core';
import { SignUpSuccessComponent } from 'app/auth/register/signup-success/signup-success.component';
import { SkeletonModule } from 'primeng/skeleton';
import { AppLayoutComponent } from 'app/components/app-layout/app-layout.component';
import { ScoreCardComponent } from 'app/components/score-card/score-card.component';
import { CountdownComponent } from 'app/components/ui/countdown/countdown.component';
import { ButtonsComponent } from 'app/components/ui/buttons/buttons.component';
import { RouterLink } from '@angular/router';
import { RoundService } from 'app/round/round.service';
import { ChangeDetectorRef } from '@angular/core';
import { SpinnerComponent } from 'app/components/ui/spinner/spinner.component';
import { DialogModule } from 'primeng/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import type { ScoreInfo } from 'types/round';
import { getFromStorage, setToStorage } from 'utils/storage';
import { getBoosterRoundInfoByRound } from 'utils';
import { environment } from 'environments/environment';
import { NotificationsService } from 'app/services/notifications/notifications.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    SignUpSuccessComponent,
    SkeletonModule,
    AppLayoutComponent,
    ScoreCardComponent,
    CountdownComponent,
    ButtonsComponent,
    RouterLink,
    SpinnerComponent,
    DialogModule,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit {
  endRoundDate: any = null;
  wasAnsweredRound: boolean = false;
  loading: boolean = true;
  hideCounter: boolean = false;
  visibleAnsweredModal: boolean = false;
  userLoading: boolean = true;
  currentRoundId = 0;
  currentRoundNumber = 0;
  lastClosedRoundNumber = 0;
  counterText: string = '';
  closedRound: boolean = false;
  waitingFirstRound: boolean = false;

  notificationsCase: string = '';
  visibleNotificationsModal: boolean = false;

  hasBoosterRound: boolean = false;
  isBoosterModalVisible: boolean = false;
  hasBoosterResults: boolean = false;
  boosterRoundResultsInfo: { id: number; number: number } = {
    id: 0,
    number: 0,
  };

  answeredModalStorage = getFromStorage('answeredModal')?.answeredModal;
  finalModalOpened: boolean =
    getFromStorage('finalModalOpened')?.finalModalOpened;
  finalRoundPased: boolean = false;

  scoreInfo: ScoreInfo = {
    team: {
      avatar: 0,
      name: '',
      center: '',
      points: 0,
    },
    user: {
      avatar: 0,
      name: '',
      points: 0,
      picture: false,
      id: 0,
    },
  };

  constructor(
    private roundService: RoundService,
    private authService: AuthService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    public messaging: NotificationsService
  ) {}

  async ngOnInit() {
    this.loading = true;

    if (!('Notification' in window)) {
      const hasSeenModal =
        getFromStorage('notificationsModal')?.notificationsModal;
      if (!hasSeenModal) this.visibleNotificationsModal = true;
    } else {
      this.messaging.requestPermission();
    }

    if (this.authService.userInfo) {
      this.scoreInfo = {
        user: {
          avatar: this.authService.userInfo.avatar,
          name: this.authService.userInfo.nickname,
          points: this.authService.userInfo.points,
          picture: this.authService.userInfo.picture,
          id: this.authService.userInfo.id,
        },
        team: {
          avatar: this.authService.userInfo.team.avatar,
          name: this.authService.userInfo.team.name,
          center: this.authService.userInfo.center.name,
          points: this.authService.userInfo.team.points,
        },
      };
      this.userLoading = false;
      this.changeDetector.detectChanges();
    } else {
      this.authService.getUserInfo().subscribe(({ user }: any) => {
        this.scoreInfo = {
          user: {
            avatar: user.avatar,
            name: user.nickname,
            points: user.points,
            picture: user.picture,
            id: user.id,
          },
          team: {
            avatar: user.team.avatar,
            name: user.team.name,
            center: user.center.name,
            points: user.team.points,
          },
        };

        this.userLoading = false;
        this.changeDetector.detectChanges();
      });
    }

    this.roundService.getCurrentRound().subscribe(({ response, code }: any) => {
      if (response?.status) {
        if (!(this.changeDetector as ViewRef).destroyed) {
          this.changeDetector.detectChanges();
        }
        this.currentRoundId = response.round.id;
        this.currentRoundNumber = response.round.number;
        this.lastClosedRoundNumber = response.round.number - 1;
        this.wasAnsweredRound = response.status === 'answered';

        this.hasBoosterRound =
          Boolean(response?.boosterRound) &&
          response?.statusBoosterRound === 'pending';
        this.isBoosterModalVisible =
          Boolean(response?.boosterRound) &&
          response?.statusBoosterRound === 'pending';

        if (response?.status === 'pending') {
          this.visibleAnsweredModal = false;
          setToStorage('answeredModal', {
            closed: false,
            round: null,
          });
          setToStorage('finalModalOpened', {
            finalModalOpened: false,
          });
        }

        this.endRoundDate = response.round.endAt;
        this.loading = false;
        this.changeDetector.detectChanges();
      } else {
        if (response.nextRoundStartsAt) {
          this.closedRound = true;
          this.endRoundDate = response.nextRoundStartsAt;
          this.wasAnsweredRound = true;

          if (!this.answeredModalStorage?.closed)
            this.visibleAnsweredModal = true;
          this.lastClosedRoundNumber = response.nextRound - 1;

          // PREVIOUS ROUND HAD BOOSTER AND RESULTS
          const ROUNDS_WITH_BOOSTER_ROUNDS = [8, 10, 12];
          if (ROUNDS_WITH_BOOSTER_ROUNDS.includes(response.nextRound - 1)) {
            this.hasBoosterResults = true;
          }
          this.boosterRoundResultsInfo = getBoosterRoundInfoByRound(
            response.nextRound - 1
          );

          if (response.nextRound === 1) {
            this.waitingFirstRound = true;
            this.visibleAnsweredModal = false;
            this.wasAnsweredRound = false;
          }
        } else {
          this.hideCounter = true;
          if (this.answeredModalStorage?.closed)
            this.lastClosedRoundNumber = this.answeredModalStorage?.round + 1;
          this.wasAnsweredRound = true;

          if (response?.message === 'No more rounds available') {
            if (!this.finalModalOpened) {
              this.visibleAnsweredModal = true;
            }

            this.lastClosedRoundNumber = 12;
            this.hasBoosterResults = true;
            this.boosterRoundResultsInfo = getBoosterRoundInfoByRound(12);
            this.finalRoundPased = true;
          }

          if (!response?.nextRound && !response?.nextRoundStartsAt) {
            if (!this.finalModalOpened) {
              this.visibleAnsweredModal = true;
            }

            this.lastClosedRoundNumber = 12;
            this.hasBoosterResults = true;
            this.boosterRoundResultsInfo = getBoosterRoundInfoByRound(12);
            this.finalRoundPased = true;
          }
        }

        this.loading = false;
        this.changeDetector.detectChanges();
      }
    });
  }

  handleCickDialog() {
    if (!this.answeredModalStorage?.closed) {
      this.handleCloseDialog();
    }

    this.router.navigate(['/rounds'], {
      queryParams: { query: this.lastClosedRoundNumber },
    });

    if (!this.finalModalOpened) {
      setToStorage('finalModalOpened', {
        finalModalOpened: true,
      });
    }
  }

  handleCickPlay() {
    if (this.wasAnsweredRound) {
      this.handleCickDialog();
      return;
    } else this.router.navigate(['/round']);
  }

  handleClickRanking() {
    if (this.currentRoundNumber === 1) return;
    else this.router.navigate(['/rankings']);
  }

  handleCloseDialog() {
    this.visibleAnsweredModal = false;
    setToStorage('answeredModal', {
      closed: true,
      round: this.lastClosedRoundNumber,
    });

    setToStorage('finalModalOpened', {
      finalModalOpened: true,
    });
  }

  handleCloseNotificationDialog() {
    this.visibleNotificationsModal = false;
    setToStorage('notificationsModal', {
      notificationsModal: true,
    });

    setToStorage('answeredModal', {
      closed: true,
      round: this.lastClosedRoundNumber,
    });
  }

  getProfileImageSrc() {
    if (this.scoreInfo.user.picture)
      return `${environment.apiUrl}/user/picture/${this.scoreInfo.user.id}`;
    return `../../../assets/avatars/${this.scoreInfo.user.avatar}.svg`;
  }

  getcounterLabel() {
    if (this.closedRound) return 'Próxima ronda en:';
    if (this.wasAnsweredRound) return 'La ronda se cierra en:';
    return 'Tiempo restante para responder:';
  }

  toggleBoosterModal() {
    this.isBoosterModalVisible = !this.isBoosterModalVisible;
  }

  handleBoosterClick() {
    this.router.navigate(['/round'], {
      queryParams: { booster: true },
    });
  }

  getLogoClass() {
    if (this.wasAnsweredRound) {
      if (this.finalRoundPased) return 'logo-container final-logo';
      else return 'logo-container answered';
    }

    return 'logo-container normal';
  }
}
