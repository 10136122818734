<section class="container">
  <app-round-card
    [label]="hasCorrectAnswer && hasBonusPoints ? '¡ENHORABUENA!' : ' '"
    [buttonLabel]="
      isInBoosterRoundresults
        ? 'Finalizar'
        : hasBoosterRound
        ? 'Siguiente'
        : 'Finalizar'
    "
    (onClickButton)="handleClick()"
    [classCard]="'container-cards results'"
  >
    @if(hasCorrectAnswer && hasBonusPoints){
    <p>También has conseguido:</p>

    @if(!isIndividualTeam && userChoice?.teamBonus){
    <div class="item-w-image">
      <svg
        width="43"
        height="43"
        viewBox="0 0 43 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="21.5391"
          cy="14.6641"
          r="5.5"
          stroke="#FFEC2D"
          stroke-width="3"
          stroke-linecap="round"
        />
        <path
          d="M27.0415 14.5391C27.5387 13.6777 28.3578 13.0493 29.3185 12.7918C30.2792 12.5344 31.3027 12.6692 32.1641 13.1665C33.0254 13.6637 33.6539 14.4828 33.9113 15.4435C34.1687 16.4042 34.0339 17.4277 33.5367 18.2891C33.0394 19.1504 32.2203 19.7789 31.2596 20.0363C30.299 20.2937 29.2754 20.1589 28.4141 19.6617C27.5527 19.1644 26.9243 18.3453 26.6668 17.3846C26.4094 16.424 26.5442 15.4004 27.0415 14.5391L27.0415 14.5391Z"
          stroke="#FFEC2D"
          stroke-width="3"
        />
        <path
          d="M9.54147 14.5391C10.0387 13.6777 10.8578 13.0493 11.8185 12.7918C12.7792 12.5344 13.8027 12.6692 14.6641 13.1665C15.5254 13.6637 16.1539 14.4828 16.4113 15.4435C16.6687 16.4042 16.5339 17.4277 16.0367 18.2891C15.5394 19.1504 14.7203 19.7789 13.7596 20.0363C12.799 20.2937 11.7754 20.1589 10.9141 19.6617C10.0527 19.1644 9.42425 18.3453 9.16684 17.3846C8.90943 16.424 9.04419 15.4004 9.54147 14.5391L9.54147 14.5391Z"
          stroke="#FFEC2D"
          stroke-width="3"
        />
        <path
          d="M30.0819 32.1641L28.6114 32.4602L28.8539 33.6641H30.0819V32.1641ZM37.0531 31.0574L38.5005 30.6636L37.0531 31.0574ZM26.4055 26.4075L25.4982 25.213L23.7634 26.5307L25.6133 27.6812L26.4055 26.4075ZM36.1563 30.6641H30.0819V33.6641H36.1563V30.6641ZM35.6058 31.4512C35.5507 31.2487 35.6065 31.0261 35.7335 30.8726C35.852 30.7292 36.0135 30.6641 36.1563 30.6641V33.6641C37.6185 33.6641 38.9507 32.3179 38.5005 30.6636L35.6058 31.4512ZM30.2891 26.6641C32.0846 26.6641 33.2433 27.4057 34.0496 28.3468C34.8965 29.3353 35.3657 30.569 35.6058 31.4512L38.5005 30.6636C38.2037 29.5728 37.5828 27.8598 36.3278 26.3949C35.0322 24.8826 33.0773 23.6641 30.2891 23.6641V26.6641ZM27.3128 27.602C28.0284 27.0584 28.9771 26.6641 30.2891 26.6641V23.6641C28.3113 23.6641 26.7274 24.2794 25.4982 25.213L27.3128 27.602ZM25.6133 27.6812C27.518 28.8658 28.3011 30.9193 28.6114 32.4602L31.5524 31.8679C31.1764 30.0012 30.1364 26.9614 27.1977 25.1337L25.6133 27.6812Z"
          fill="#FFEC2D"
        />
        <path
          d="M16.6727 26.4075L17.4649 27.6812L19.3149 26.5307L17.58 25.213L16.6727 26.4075ZM6.02509 31.0574L7.47247 31.4512L6.02509 31.0574ZM12.9963 32.1641V33.6641H14.2243L14.4668 32.4602L12.9963 32.1641ZM12.7892 26.6641C14.1012 26.6641 15.0498 27.0584 15.7654 27.602L17.58 25.213C16.3509 24.2794 14.767 23.6641 12.7892 23.6641V26.6641ZM7.47247 31.4512C7.71253 30.569 8.18175 29.3353 9.02869 28.3468C9.83493 27.4057 10.9937 26.6641 12.7892 26.6641V23.6641C10.001 23.6641 8.0461 24.8826 6.75045 26.3949C5.49549 27.8598 4.87453 29.5728 4.57772 30.6635L7.47247 31.4512ZM6.92194 30.6641C7.06472 30.6641 7.22624 30.7292 7.34477 30.8726C7.47176 31.0262 7.52757 31.2487 7.47247 31.4512L4.57772 30.6635C4.12755 32.3179 5.45975 33.6641 6.92194 33.6641V30.6641ZM12.9963 30.6641H6.92194V33.6641H12.9963V30.6641ZM14.4668 32.4602C14.7771 30.9193 15.5602 28.8658 17.4649 27.6812L15.8805 25.1337C12.9419 26.9614 11.9018 30.0012 11.5258 31.8679L14.4668 32.4602Z"
          fill="#FFEC2D"
        />
        <path
          d="M21.5391 25.1641C28.4541 25.1641 29.9043 30.629 30.2084 32.9211C30.281 33.4686 29.8413 33.9141 29.2891 33.9141H13.7891C13.2368 33.9141 12.7971 33.4686 12.8698 32.9211C13.1739 30.629 14.624 25.1641 21.5391 25.1641Z"
          stroke="#FFEC2D"
          stroke-width="3"
          stroke-linecap="round"
        />
      </svg>

      <h5 class="">TEAM BONUS</h5>
      <h4 class="score">
        {{ userChoice.teamBonus }}
        <img src="assets/svg/sun.svg" alt="" />
      </h4>
    </div>
    }

    <div class="item-w-image">
      @if(userChoice.mondayBonus){
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="4.75"
          y="9.5"
          width="28.5"
          height="23.75"
          rx="2"
          stroke="#FFEC2D"
          stroke-width="2"
        />
        <path
          d="M4.75 13.5C4.75 11.6144 4.75 10.6716 5.33579 10.0858C5.92157 9.5 6.86438 9.5 8.75 9.5H29.25C31.1356 9.5 32.0784 9.5 32.6642 10.0858C33.25 10.6716 33.25 11.6144 33.25 13.5V15.8333H4.75V13.5Z"
          fill="#FFEC2D"
        />
        <path
          d="M11.0835 4.75L11.0835 9.5"
          stroke="#FFEC2D"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M26.9165 4.75L26.9165 9.5"
          stroke="#FFEC2D"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>

      <h5 class="">MONDAY BONUS</h5>
      <h4 class="score">
        {{ userChoice.mondayBonus }}
        <img src="assets/svg/sun.svg" alt="" />
      </h4>
      } @else { @if(userChoice?.speedBonus){

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="31"
        viewBox="0 0 25 31"
        fill="none"
      >
        <path
          d="M12.5393 15.5284L3.22692 9.1897C2.67976 8.81726 2.3523 8.19825 2.3523 7.53637L2.3523 2.9308C2.3523 2.65466 2.57615 2.4308 2.8523 2.4308L22.2263 2.4308C22.5025 2.4308 22.7263 2.65466 22.7263 2.9308L22.7263 7.53637C22.7263 8.19825 22.3989 8.81727 21.8517 9.1897L12.5393 15.5284ZM12.5393 15.5284L3.22692 21.8671C2.67976 22.2395 2.35229 22.8585 2.35229 23.5204L2.35229 28.126C2.35229 28.4021 2.57615 28.626 2.85229 28.626L22.2263 28.626C22.5025 28.626 22.7263 28.4021 22.7263 28.126L22.7263 23.5204C22.7263 22.8585 22.3989 22.2395 21.8517 21.8671L12.5393 15.5284Z"
          stroke="#FFEC2D"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.7183 5.79169L6.7183 2.58059C6.7183 2.49775 6.78546 2.43059 6.8683 2.43059L18.2106 2.43059C18.2934 2.43059 18.3606 2.49775 18.3606 2.58059L18.3606 5.79169C18.3606 5.96408 18.2718 6.12432 18.1256 6.21569L12.9634 9.44203C12.704 9.60417 12.3749 9.60417 12.1154 9.44203L6.9533 6.21569C6.80711 6.12432 6.7183 5.96408 6.7183 5.79169Z"
          fill="#FFEC2D"
        />
      </svg>
      <h5 class="">SPEED BONUS</h5>
      <h4 class="score">
        {{ userChoice.speedBonus }}
        <img src="assets/svg/sun.svg" alt="" />
      </h4>
      } }
    </div>
    }

    <h2 class="summary-title">
      {{ getResumeLabel() }}
    </h2>
    <div class="item-w-image">
      <img [src]="getProfileImageSrc()" alt="" class="app-profile-picture" />
      <b>PUNTUACIÓN INDIVIDUAL:</b>

      <h4 class="score">
        {{ roundedNumber(scoreInfo.user.points) }}
        <img src="assets/svg/sun.svg" alt="" />
      </h4>
    </div>

    @if(!isIndividualTeam){
    <div class="item-w-image">
      <img
        [src]="'../../assets/logos/' + scoreInfo.team.avatar + '.svg'"
        alt=""
      />
      <b>PUNTUACIÓN EQUIPO:</b>

      <h4 class="score">
        {{ roundedNumber(scoreInfo.team.points) }}
        <img src="assets/svg/sun.svg" alt="" />
      </h4>
    </div>
    }
  </app-round-card>
</section>
