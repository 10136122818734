<section class="container">
  <span class="label">{{ label }}</span>

  <div class="content">
    <div class="date-container">
      <div class="date-content">
        <span>{{ countdown.days.firstDigit }}</span>
        <span>{{ countdown.days.secondDigit }}</span>
      </div>
      <p>días</p>
    </div>

    <div class="date-container">
      <div class="date-content">
        <span>{{ countdown.hours.firstDigit }}</span>
        <span>{{ countdown.hours.secondDigit }}</span>
      </div>
      <p>horas</p>
    </div>

    <div class="date-container">
      <div class="date-content">
        <span>{{ countdown.minutes.firstDigit }}</span>
        <span>{{ countdown.minutes.secondDigit }}</span>
      </div>
      <p>minutos</p>
    </div>
  </div>
</section>
