<auth-layout>
  <div class="logos">
    <img
      class="logo-laRoche"
      src="../../../assets/logo-laRoche.svg"
      alt="logo de Anthelios league"
    />
    <div class="title">
      <h1>CONCURSO DE CASOS CLÍNICOS</h1>
    </div>
    <div class="logo-anthelios">
      <img
        src="../../../assets/logo-anthelios.svg"
        alt="logo Anthelios League"
      />
    </div>
    <div class="description">
      <p class="text-description">
        LA COMPETICIÓN DE LOS EXPERTOS <br />
        EN FOTOPROTECCIÓN <br />
        Y MUCHO MÁS
      </p>
    </div>
  </div>
</auth-layout>
